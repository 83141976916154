<template>
  <van-config-provider theme="dark">
  <div id="app">
    <van-nav-bar title="开饭吗？" />
    <van-cell-group class=form inset>
      <van-cell title="当前时间" :value="currentTime" />
      <van-cell
        title="开饭时间"
        :value="targetTime.join(':')"
        @click="showDialog('target')"
        is-link
      />
      <van-cell
        title="煮"
        :value="`${cookTime[0] && cookTime[0] + '小时'}${cookTime[1]}分钟`"
        @click="showDialog('cook')"
        is-link
      />
      <van-cell title="需要定时" :value="waitTime" />
    </van-cell-group>
    <!-- <van-action-sheet :show="dialogData.show"> -->
    <!-- <van-overlay :show="dialogData.show"> -->
    <transition name="van-slide-up">
      <van-time-picker
        class="van-time-picker"
        v-if="dialogData.show"
        v-model="dialogData.time"
        title="选择时间"
        @confirm="dialogConfirm(true)"
        @cancel="dialogConfirm(false)"
      />
    </transition>
    <!-- </van-overlay> -->
    <!-- </van-action-sheet> -->
  </div>
  </van-config-provider>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: 'App',
  data() {
    return {
      currentTime: '',
      targetTime: ['7', '30'],
      cookTime: ['1', '00'],
      dialogData: {
        show: false,
        time: ['', ''],
        type: '',
      },
    };
  },
  computed: {
    waitTime() {
      const currentTime = dayjs();
      let targetTime = dayjs()
        .hour(+this.targetTime[0])
        .minute(this.targetTime[1]);

      if (targetTime.diff(currentTime) < 0) {
        targetTime = targetTime.add(24, 'hours');
      }

      targetTime = targetTime
        .add(-+this.cookTime[0], 'hour')
        .add(-+this.cookTime[1], 'minutes');
      const hours = targetTime.diff(currentTime, 'hour');
      const minutes = targetTime.diff(currentTime, 'minute') - hours * 60;
      return minutes < 0 ? '来不及啦' : `${hours}小时${minutes}分钟`;
    },
  },
  created() {
    this.currentTimetimer = setInterval(() => {
      const currentDate = new Date();
      this.currentTime = dayjs().format('HH:mm:ss');
    }, 100);
    const currentDate = new Date();
    this.currentTime = dayjs().format('HH:mm:ss');
  },
  beforeUnmount() {
    clearInterval(this.currentTimetimer);
  },
  methods: {
    showDialog(type) {
      if (type === 'target') {
        this.dialogData.time = this.targetTime;
      } else if (type === 'cook') {
        this.dialogData.time = this.cookTime;
      }
      this.dialogData.type = type;
      this.dialogData.show = true;
    },
    dialogConfirm(confirm) {
      const { type, time } = this.dialogData;
      if (confirm) {
        if (type === 'target') {
          this.targetTime = time;
        } else if (type === 'cook') {
          this.cookTime = time;
        }
      } else {
      }
      this.dialogData.show = false;
    },
  },
};
</script>

<style lang="less">
#app {
  color: #f5f5f5;
  background-color: black;
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-sizing: border-box;

  > * {
    user-select: none;
  } 
  
  .form {
    margin-top: 30px;
  }
  .van-time-picker {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px 0px 20px 0px rgba(11, 29, 83, 0.1);
  }
}
</style>
