const { createApp } = require('vue');
import App from './App.vue';
import 'vant/lib/index.css';
import * as vant from 'vant';
import '@vant/touch-emulator';

const app = createApp(App);
const reg = /^[A-Z][A-Za-z]+$/;
Object.entries(vant).forEach(([key, component]) => {
  if (component && component.name && reg.test(key)) {
    // console.log('reg', component.name, key);
    app.use(component);
  }
});
app.mount('#app');
